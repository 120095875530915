import createAnAccount from 'assets/images/createAnAccount.jpg'
import { useDispatch } from 'react-redux'
import { setAuthModal } from 'store/auth'
import BannerCardSection from 'components/sections/BannerCardSection'
import { ArrowRightIcon } from 'components/icons'

const CreateAnAccount = () => {
  const dispatch = useDispatch()

  return (
    <BannerCardSection
      includePadding={false}
      className={{ container: 'mb-75 lg:mb-0' }}
      bannerImage={createAnAccount}
      altText="Picture of a dining table in front of a river at sunset"
      subtitle="Create An Account"
      heading="Get insider travel inspiration straight to your inbox."
      content="Create an account with us and stay up-to-date with our exceptional properties, the hottest restaurants and calendar worthy events. Get inspired for your travels to Europe’s most fashionable destinations."
      buttons={[
        {
          text: 'Join Now',
          action: () => dispatch(setAuthModal({ modal: 'register', open: true })),
          icon: <ArrowRightIcon className="stroke-2" />,
        },
      ]}
    />
  )
}

export default CreateAnAccount
